/*===► tailwind ◄===*/
@tailwind base;
@tailwind components;
@tailwind utilities;

/*=== bring in normalize.css styles ===*/
@import-normalize;
/*=== custom fonts ===*/
@import url('https://fonts.googleapis.com/css2?family=Iceland&family=Rampart+One&family=Roboto:wght@400;500;700&display=swap');

/*=== trio header ===*/
* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   /*=== color theme - change or remove if work out ===*/
   --light: #fff6da;
   --dark: #262525;
   --color1: #fc6b3f;
   --color2: #84f2d6;
   --color3: #cd5c5c;
}
html {
   margin: 0;
   padding: 0;
   scroll-behavior: smooth;
   scroll-padding: 75px;
}
body {
   margin: 0;
   padding: 0;
   font: 400 16px 'Roboto', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   overflow-x: hidden;
   background: var(--dark) url('./assets/images/bg/dark-ripple.svg');
   /* background: var(--dark) url('assets/images/bg/temple.svg'); */
   color: var(--light);
}
/*=== other defaults ===*/
code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* h1,h2,h3,h4,h5,h6 {
   margin: 0;
   padding: 0;
   line-height: normal;
} */
p,
a,
li,
button,
ul {
   margin: 0;
   padding: 0;
   line-height: normal;
   text-decoration: none;
}
a:hover {
   text-decoration: none;
}
img {
   width: 100%;
   height: auto;
}
button {
   border: 0;
   background-color: transparent;
}
input:focus,
textarea:focus,
select:focus {
   outline: none;
}
.success {
   color: green;
}

.danger {
   color: red;
}

/*=== media queries ===*/
/* @media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
} */
